import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useProfile } from "../../ProfileContext";

const RedirectIfLoggedIn = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { loading } = useProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to /profile if user is authenticated and not on login path
      if (location.pathname === "/login" || location.pathname === "/signup") {
        navigate("/profile");
      }
    } else if (!loading) {
      setIsLoading(false);
    }
  }, [isAuthenticated, loading, navigate, location.pathname]);

  if (isLoading) {
    return null; // Optionally, you can add a loading spinner here
  }

  return children;
};

export default RedirectIfLoggedIn;
