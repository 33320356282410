import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";

function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useAuth(); // Get isAuthenticated and loading from AuthContext

  if (loading) {
    return null; // Optionally, show a loading spinner or message here
  }

  if (!isAuthenticated) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the requested route
  return children;
}

export default ProtectedRoute;
