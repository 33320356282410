class Product {
  constructor(id, name, grams, calories, protein, fat, carbs) {
    this.id = id;
    this.name = name;
    this.grams = grams;
    this.calories = calories;
    this.protein = protein;
    this.fat = fat;
    this.carbs = carbs;
  }
}

export default Product;
