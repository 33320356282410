import Activity from "./Activity"; // Adjust the import path if necessary

class DietProfile {
  // Properties with default values
  name = "";
  age = null;
  gender = "";
  weight = null; // in kg
  height = null; // in cm
  waist = null; // in cm
  hips = null; // in cm
  goalWeight = null; // in kg
  activity = new Activity(); // Default empty Activity
  bodyMassIndex = null;
  dailyCalorieNorm = null;
  selectedCalorieLimit = null;
  dailyProteinLimit = null;
  dailyFatLimit = null;
  dailyCarbsLimit = null;
  proteinPercentage = null;
  carbsPercentage = null;
  fatPercentage = null;

  // You can add methods here if needed
}

export default DietProfile;
