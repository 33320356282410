export function formatDate(date) {
  const pad = (num) => num.toString().padStart(2, "0");

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());

  return `${year}-${month}-${day}`;
}
export function getNextDay(date) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate;
}
export const formatDateToYYYYMMDD = (date) => {
  if (!(date instanceof Date)) {
    throw new Error("Input must be a Date object");
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    .toISOString()
    .split("T")[0];
};
