import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./ExportWindow.module.css";
import { formatDate, getNextDay } from "../../../../utils/dateUtils";
import "./datepickerStyles.scss";
import { useTranslation } from "react-i18next";
import { enGB, uk as ukLocale } from "date-fns/locale"; // Ensure you're importing the correct locales

const ExportWindow = ({ selectedProfile, t }) => {
  const { i18n } = useTranslation(); // Access the i18n object
  const locale = i18n.language === "en" ? enGB : ukLocale; // Use the locale objects
  const [showModal, setShowModal] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const handleExportClick = () => {
    console.log("Current Locale:", locale); // Check what the current locale is

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExportExcel = async () => {
    const exportDateStart = formatDate(selectionRange.startDate);
    const exportDateEnd = formatDate(
      selectionRange.endDate || getNextDay(selectionRange.startDate)
    );
    const url = `${process.env.REACT_APP_API_URL}/ration/export?profileId=${selectedProfile.id}&startDate=${exportDateStart}&endDate=${exportDateEnd}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          "ngrok-skip-browser-warning": "true",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX MIME type
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a link and trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `${selectedProfile.name}.docx`;
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Clean up the object URL
      window.URL.revokeObjectURL(downloadUrl);

      // Close modal after successful download
      handleCloseModal();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <>
      <button
        className={styles["export-ration-button"]}
        onClick={handleExportClick}
      >
        {t(`diet.dietPlan.exportButton`)}
      </button>

      {showModal && (
        <div className={styles["modal"]}>
          <div className={styles["modal-content"]}>
            <h2>{t("diet.dietPlan.selectDateRange")}</h2>

            <div className={styles["date-picker-container"]}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={(item) => setSelectionRange(item.selection)}
                className={styles["custom-date-picker"]}
                rangeColors={["#548054"]}
                locale={locale} // Use the determined locale
              />
            </div>

            <div className={styles["modal-actions"]}>
              <button
                className={styles["export-button"]}
                onClick={handleExportExcel}
                disabled={!selectionRange.startDate || !selectionRange.endDate} // Ensure both dates are selected
              >
                {t("diet.dietPlan.export")}
              </button>
              <button
                className={styles["cancel-button"]}
                onClick={handleCloseModal}
              >
                {t("diet.dietPlan.cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExportWindow;
