import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const RedirectIfNoProfiles = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Fetch profiles and loading state from Redux
  const { profiles, profilesLoading } = useSelector((state) => state.profilesData);

  useEffect(() => {
    // Only redirect if profiles are done loading and there are no profiles available
    if (!profilesLoading && (!profiles || profiles.length === 0)) {
      // Redirect only for specific routes
      if (
        location.pathname === "/profile" ||
        location.pathname === "/diet" ||
        location.pathname === "/product-list"
      ) {
        navigate("/add-profile");
      }
    }
  }, [profiles, profilesLoading, location.pathname, navigate]);

  // Only render children once profiles are loaded
  return profiles && profiles.length > 0 ? children : null;
};

export default RedirectIfNoProfiles;
