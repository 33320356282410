import React from "react";
import { Outlet } from "react-router-dom";
import "./HomePage.css";
import Header from "./Header/Header";

const HomePage = ({ children }) => {
  return (
    <div className="home-layout">
      <Header />
      {children || <Outlet />}{" "}
      {/* Render children if provided, otherwise render Outlet */}
    </div>
  );
};

export default HomePage;
