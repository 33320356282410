import React, { useState, useEffect, useRef } from "react";
import { useProfile } from "../../../ProfileContext";
import styles from "./Profile.module.css";
import DietProfile from "../../../models/DietProfile";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import {
  setUser,
  fetchActivities,
  setSelectedActivity,
  updateProfile,
} from "../../../redux/slices/profileSlice";
import useFetchUser from "../../../hooks/useFetchUser";
import { useTranslation } from "react-i18next"; // Import useTranslation from react-i18next
import {
  calculateDailyCalorieNorm,
  calculateGramsFromPercentage,
} from "../../../utils/healthCalculations";
import { validateDigitsInput } from "../../../utils/inputUtils";

const Profile = () => {
  const { t } = useTranslation(); // Use the translation hook
  const { selectedProfile } = useProfile();
  const dispatch = useDispatch();
  const { user, activities } = useSelector((state) => state.profile);
  const [initialUser, setInitialUser] = useState(new DietProfile());
  const [initialized, setInitialized] = useState(false);
  const [refetch, setRefetch] = useState(true);

  const activeBorderColor = "rgb(72, 147, 56)"; // Define your primary color variable

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      padding: "0 4px",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
      borderColor: activeBorderColor, // Use the color directly for editing state
      borderRadius: "12px",
      boxShadow: "0 0 0 0.5px" + activeBorderColor + "inset", // Shadow when editing
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: activeBorderColor,
        boxShadow: state.isFocused
          ? "0 0 0 0.5px" + activeBorderColor + "inset"
          : "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#e0f7e0"
        : state.isFocused
        ? "#f1f1f1"
        : "#ffffff",
      color: state.isSelected ? "#495057" : "#495057",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      "&:active": {
        backgroundColor: "#c8e6c9",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    input: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const genderSelectStyles = {
    container: (provided) => ({
      ...provided,
      padding: "0 0 0 2px ",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
      border: "none",
      borderRadius: "12px",
      boxShadow: "none",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      transition: "border-color 0.2s, box-shadow 0.2s",
      padding: "0", // Ensure padding is removed
      "&:hover": {
        borderColor: "none",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#e0f7e0"
        : state.isFocused
        ? "#f1f1f1"
        : "#ffffff",
      color: state.isSelected ? "#495057" : "#495057",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      "&:active": {
        backgroundColor: "#c8e6c9",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    input: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  // Fetch user data
  // Pass setInitialized directly as onUserStateSet
  const { loading } = useFetchUser(
    setInitialUser,
    setInitialized,
    null,
    refetch
  );

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Handle percentage input specifically
    let updatedValue = value;
    if (
      name === "dailyProteinLimit" ||
      name === "dailyFatLimit" ||
      name === "dailyCarbsLimit"
    ) {
      // Extract numeric value before the '%' symbol
      const numericValue = value.match(/(\d+)%?/); // Match digits followed optionally by '%'
      updatedValue = numericValue ? numericValue[1] : ""; // Get the first match or empty string
    } else {
      updatedValue =
        name === "name" || name === "gender"
          ? value
          : validateDigitsInput(value);
    }

    const isPcf =
      name === "dailyProteinLimit" ||
      name === "dailyFatLimit" ||
      name === "dailyCarbsLimit";
    const key = isPcf ? name.replace(/daily|Limit/g, "") : "";

    // Update the user state while preserving the selected activity
    let updatedUserState = {};
    if (isPcf) {
      updatedUserState = {
        ...user, // Keep all existing fields
        [`daily${key}Percentage`]: updatedValue, // Only change the percentage field
      };
    } else {
      updatedUserState = {
        ...user, // Keep all existing fields
        [name]: updatedValue, // Only change the field being updated
      };
    }
    dispatch(setUser(updatedUserState));
  };

  function useDeepCompareEffect(callback, dependencies) {
    const previousDepsRef = useRef();

    // Only execute effect if dependencies have changed deeply
    if (!isEqual(previousDepsRef.current, dependencies)) {
      previousDepsRef.current = dependencies;
    }

    useEffect(callback, [previousDepsRef.current]);
  }

  useDeepCompareEffect(() => {
    if (initialized && user) {
      debouncedHandleSave();
    }

    return () => {
      debouncedHandleSave.cancel();
    };
  }, [user]);

  const debouncedHandleSave = debounce(async () => {
    if (!user.activity) {
      return;
    }
    const updatedProfile = {
      name: user.name,
      age: user.age,
      gender: user.gender,
      height: user.height,
      weight: user.weight,
      waist: user.waist,
      hips: user.hips,
      goalWeight: user.goalWeight,
      activity: user.activity,
      bodyMassIndex: user.bodyMassIndex,
      dailyCalorieNorm: user.dailyCalorieNorm,
      selectedCalorieLimit: user.selectedCalorieLimit,
      dailyProteinLimit: user.dailyProteinLimit,
      dailyFatLimit: user.dailyFatLimit,
      dailyCarbsLimit: user.dailyCarbsLimit,
      dailyProteinPercentage: user.dailyProteinPercentage,
      dailyCarbsPercentage: user.dailyCarbsPercentage,
      dailyFatPercentage: user.dailyFatPercentage,
    };

    // Check if any relevant fields have changed
    const generalInfoHasChanges =
      updatedProfile.age !== initialUser.age ||
      updatedProfile.gender !== initialUser.gender ||
      updatedProfile.weight !== initialUser.weight ||
      updatedProfile.height !== initialUser.height ||
      updatedProfile.activity.id !== initialUser.activity.id;

    const weight = updatedProfile.weight || 0;
    const height = updatedProfile.height || 0;
    const age = updatedProfile.age || 0;
    const activityCoef = updatedProfile.activity.multiplier || 1;
    const gender = updatedProfile.gender || "unknown";

    if (generalInfoHasChanges) {
      const dailyCalorieNorm =
        weight && height && age
          ? calculateDailyCalorieNorm({
              weight,
              height,
              age,
              gender,
              activityCoef,
            })
          : updatedProfile.dailyCalorieNorm || "";

      updatedProfile.dailyCalorieNorm = dailyCalorieNorm;
      updatedProfile.bodyMassIndex =
        weight && height
          ? (weight / (height / 100) ** 2).toFixed(2)
          : updatedProfile.bodyMassIndex;
      // recalculate all to defaults
      updatedProfile.selectedCalorieLimit = updatedProfile.dailyCalorieNorm;
    }
    const selectedCalorieLimitHasChanges =
      updatedProfile.selectedCalorieLimit !== initialUser.selectedCalorieLimit;
    const dailyProteinPercentageHasChanges =
      user.dailyProteinPercentage !== initialUser.dailyProteinPercentage;
    const dailyCarbsPercentageHasChanges =
      user.dailyCarbsPercentage !== initialUser.dailyCarbsPercentage;
    const dailyFatPercentageHasChanges =
      user.dailyFatPercentage !== initialUser.dailyFatPercentage;

    if (selectedCalorieLimitHasChanges) {
      const dailyCalorieNorm =
        weight && height && age
          ? calculateDailyCalorieNorm({
              weight,
              height,
              age,
              gender,
              activityCoef,
            })
          : updatedProfile.dailyCalorieNorm || "";
      const selectedCalorieLimit =
        updatedProfile.selectedCalorieLimit <= 0
          ? dailyCalorieNorm
          : updatedProfile.selectedCalorieLimit;

      if (
        updatedProfile.selectedCalorieLimit &&
        updatedProfile.selectedCalorieLimit > 0 &&
        user.dailyProteinPercentage === 0 &&
        user.dailyCarbsPercentage === 0 &&
        user.dailyFatPercentage === 0
      ) {
        updatedProfile.dailyProteinPercentage = 30;
        updatedProfile.dailyCarbsPercentage = 40;
        updatedProfile.dailyFatPercentage = 30;
      }

      updatedProfile.dailyProteinLimit = calculateGramsFromPercentage(
        updatedProfile.dailyProteinPercentage,
        "protein",
        selectedCalorieLimit
      );
      updatedProfile.dailyFatLimit = calculateGramsFromPercentage(
        updatedProfile.dailyFatPercentage,
        "fat",
        selectedCalorieLimit
      );
      updatedProfile.dailyCarbsLimit = calculateGramsFromPercentage(
        updatedProfile.dailyCarbsPercentage,
        "carbs",
        selectedCalorieLimit
      );
    } else {
      if (
        dailyProteinPercentageHasChanges ||
        dailyFatPercentageHasChanges ||
        dailyCarbsPercentageHasChanges
      ) {
        const selectedCalorieLimit = user.selectedCalorieLimit;
        if (selectedCalorieLimit < 0) {
          return;
        }
        updatedProfile.dailyProteinLimit = calculateGramsFromPercentage(
          user.dailyProteinPercentage,
          "protein",
          selectedCalorieLimit
        );
        updatedProfile.dailyFatLimit = calculateGramsFromPercentage(
          user.dailyFatPercentage,
          "fat",
          selectedCalorieLimit
        );
        updatedProfile.dailyCarbsLimit = calculateGramsFromPercentage(
          user.dailyCarbsPercentage,
          "carbs",
          selectedCalorieLimit
        );
      }
    }
    updatedProfile.activity = updatedProfile.activity.id;

    try {
      await dispatch(
        updateProfile({ selectedProfileId: selectedProfile.id, updatedProfile })
      ).unwrap();
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setRefetch((prev) => !prev);
    }
  }, 500);

  const handleActivityChange = (selectedOption) => {
    dispatch(setSelectedActivity(selectedOption));
  };

  useEffect(() => {
    dispatch(fetchActivities());
  }, [dispatch]);

  useEffect(() => {
    if (user.activity) {
      const selectedOption = activities.find(
        (activity) => activity.id === user.activity.id
      );
      if (!selectedOption) {
        return;
      }
      dispatch(
        setSelectedActivity({
          value: selectedOption.id,
          label: selectedOption.description,
          multiplier: selectedOption.multiplier,
        })
      );
    }
  }, [dispatch]);

  const renderInputField = (field, labelKey, unitKey) => (
    <div
      className={`${styles["info-field"]} align-items-center ${styles.editable}`}
      key={field}
    >
      <div className="">
        <label htmlFor={field} className={`${styles["info-field-label"]}`}>
          {t(labelKey)} {unitKey ? `(${t(unitKey)})` : ""}
        </label>
        <input
          className={`${styles["info-field-input"]}`}
          id={field}
          name={field}
          value={user[field] || ""}
          onChange={handleChange}
        />
      </div>
    </div>
  );

  const renderGenderDropdown = (field) => {
    const genderOptions = [
      { value: "Male", label: t("profile.male") },

      { value: "Female", label: t("profile.female") },
      { value: "Other", label: t("profile.other") },
    ];

    return (
      <div
        className={`${styles["gender-select-warpper"]} ${styles.editable}`}
        key={field}
      >
        <div>
          <label htmlFor={field} className={`${styles["gender-select-label"]}`}>
            {t("profile.gender")}
          </label>
          <Select
            inputId={field}
            options={genderOptions}
            value={
              genderOptions.find((option) => option.value === user.gender) ||
              null
            }
            onChange={(option) =>
              handleChange({ target: { name: field, value: option.value } })
            }
            styles={genderSelectStyles}
            placeholder={t("profile.selectGender")}
          />
        </div>
      </div>
    );
  };

  const renderDropdown = () => (
    <Select
      options={activities.map((activity) => ({
        value: activity.id,
        label: activity.description,
        multiplier: activity.multiplier,
      }))}
      value={
        user.activity
          ? {
              value: user.activity.id,
              label: user.activity.description,
              multiplier: user.activity.multiplier,
            }
          : null
      }
      onChange={handleActivityChange}
      styles={customStyles}
      placeholder={t("profile.selectActivity")} // Use translation key for placeholder
      classNamePrefix="select" // Ensure CSS module styles are applied
    />
  );

  const renderSelectedField = (field, labelKey, unitKey, editable) => {
    const isPcf =
      labelKey === "Carbs" || labelKey === "Protein" || labelKey === "Fat";
    return (
      <div className={`${styles["card-col"]} col`} key={field}>
        <div
          className={`${styles.card} card h-100 radius-16 shadow-none mb-0 ${
            editable ? styles.editable : ""
          }`}
        >
          <div className={`${styles["card-body"]} card-body text-black`}>
            <div className={`${styles["card-text"]}`}>
              <label className={styles["card-label"]} htmlFor={field}>
                {isPcf
                  ? t(`profile.${labelKey.toLowerCase()}`)
                  : t(`profile.${labelKey}`)}
              </label>

              <div className={`${styles["input-container"]}`}>
                <div className={`${isPcf ? styles["input-wrapper"] : ""}`}>
                  <input
                    id={field}
                    name={field}
                    value={
                      user[field] === null
                        ? ""
                        : isPcf
                        ? `${user[`daily${labelKey}Percentage`]}`
                        : `${user[field]}${
                            editable ? "" : " " + t(`profile.${unitKey}`)
                          }`
                    }
                    readOnly={!editable}
                    className={`${styles["card-input"]} ${
                      isPcf ? styles["pcf-input"] : ""
                    }`}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  {isPcf ? `(${user[field]} ${t(`profile.${unitKey}`)})` : ""}
                </span>
              </div>
            </div>
            <div className="ms-auto fs-2"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    user !== undefined &&
    user.name !== undefined &&
    user.age !== undefined && (
      <div className={`${styles.container} container`}>
        <div
          className={`row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-xl-2 row-cols-xxl-2 w-100 gx-0`}
        >
          <div
            className={`${styles["left-side"]} col-5 col-sm-5 col-md-5 col-lg-5 col-xl-4 col-xxl-4 offset-xl-1 offset-xxl-1`}
          >
            {/* Client info table */}
            <div className={`${styles["client-info"]}`}>
              <div className={`${styles["header-1"]} ${styles["header-text"]}`}>
                {t(`profile.clientInfo`)}
              </div>
              <div className={`${styles["info-box"]}`}>
                <div className={`${styles["info-top-box"]} ${styles.editable}`}>
                  <div className="">
                    <img
                      src="https://i.imgur.com/OMcdAnC.png"
                      className={`${styles["rounded-circle"]}`}
                      alt="Profile Avatar"
                    />
                  </div>
                  <div className={styles["info-field-container"]}>
                    <div className="">
                      <label
                        htmlFor="name"
                        className={`${styles["info-field-label"]}`}
                      >
                        {t("profile.fullName")}
                      </label>
                      <input
                        className={`${styles["info-field-input"]}`}
                        id="name"
                        name="name"
                        value={user.name || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="age"
                        className={`${styles["info-field-label"]}`}
                      >
                        {t("profile.age")}
                      </label>
                      <input
                        className={`${styles["info-field-input"]}`}
                        id="age"
                        name="age"
                        value={user.age || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {renderGenderDropdown("gender")}
                {renderInputField("weight", "profile.weight", "profile.kg")}
                {renderInputField("height", "profile.height", "profile.cm")}
                {renderInputField("waist", "profile.waist", "profile.cm")}
                {renderInputField("hips", "profile.hips", "profile.cm")}
                {renderInputField(
                  "goalWeight",
                  "profile.aimWeight",
                  "profile.kg"
                )}
              </div>
            </div>
          </div>
          <div
            className={`${styles["right-side"]} col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7`}
          >
            {/* Selected limit table */}
            <div className={styles["dietary-info"]}>
              <div className={`${styles["dietary-data"]} row`}>
                <div
                  className={`${styles["header-1"]} ${styles["header-text"]}`}
                >
                  {t(`profile.dietaryData`)}
                </div>
                <div
                  className={`${styles["card-container"]} row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-xl-1 row-cols-xxl-1`}
                >
                  {renderDropdown()} {/* Render the dropdown */}
                  {renderSelectedField(
                    "bodyMassIndex",
                    "bodyMassIndex",
                    "",
                    false
                  )}
                  {renderSelectedField(
                    "dailyCalorieNorm",
                    "dailyCalorieNorm",
                    "kcal",
                    false
                  )}
                  {renderSelectedField(
                    "selectedCalorieLimit",
                    "selectedCalorieLimit",
                    "kcal",
                    true
                  )}
                </div>
                <div>
                  <div
                    className={`${styles["card-narrow-container"]} row row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-xl-3 row-cols-xxl-3`}
                  >
                    {renderSelectedField(
                      "dailyProteinLimit",
                      "Protein",
                      "g",
                      true
                    )}
                    {renderSelectedField("dailyFatLimit", "Fat", "g", true)}
                    {renderSelectedField("dailyCarbsLimit", "Carbs", "g", true)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Profile;
