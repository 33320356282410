import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, resetProfileState } from "./redux/slices/profileSlice";
import { fetchProfiles, resetProfilesState } from "./redux/slices/profilesDataSlice";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  // Axios interceptor to catch 401 errors
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // Handle token expiration or manual invalidation
          localStorage.removeItem("jwtToken");
          setIsAuthenticated(false);
          navigate("/login"); // Redirect to login page
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor); // Cleanup the interceptor on unmount
    };
  }, [navigate]);

  useEffect(() => {
    // Check if there's a token in localStorage and validate it
    const checkAuthStatus = async () => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        try {
          // Optionally, make a request to validate the token here
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Token validation failed:", error);
          localStorage.removeItem("jwtToken"); // Remove invalid token
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false); // Set loading to false once the check is complete
    };
    checkAuthStatus();
  }, []);

  // Signup function for registering new users
  const signup = async (firstName, lastName, email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          firstName,
          lastName,
          login: email, // login refers to the user's email
          password,
        }
      );

      const token = response.data.token;
      localStorage.setItem("jwtToken", token);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error signing up:", error);
      // Handle the error appropriately in your application
    }
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          login: email,
          password: password,
        }
      );

      const token = response.data.token;
      localStorage.setItem("jwtToken", token);
      setIsAuthenticated(true);

      // Fetch user profile information after login
      const profilesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "true",
        },
      });

      // Dispatch actions to update Redux state
      dispatch(setUser(profilesResponse.data));
      dispatch(fetchProfiles()); // If you use fetchProfiles to get a list of profiles or other relevant data

      navigate("/");
    } catch (error) {
      console.error("Error logging in:", error);
      // Handle the error appropriately in your application
    }
  };

  const logout = () => {
    localStorage.removeItem("jwtToken");
    setIsAuthenticated(false);
    dispatch(resetProfileState()); // Clear profile state
    dispatch(resetProfilesState()); // Clear profiles data state
    navigate("/login");
  };


  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, signup, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
