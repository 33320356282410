import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchProfiles = createAsyncThunk(
  "profilesData/fetchProfiles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiUrl}/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          "ngrok-skip-browser-warning": "true",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const profilesDataSlice = createSlice({
  name: "profilesData",
  initialState: {
    profiles: [],
    selectedProfile: null,
    profilesLoading: false,
    error: null,
  },
  reducers: {
    setSelectedProfile: (state, action) => {
      state.selectedProfile = action.payload;
    },
    resetProfilesState: (state) => {
      return {
        profiles: [],
        selectedProfile: null,
        profilesLoading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.pending, (state) => {
        state.profilesLoading = true;
        state.error = null;
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.profiles = action.payload;
        state.profilesLoading = false;
      })
      .addCase(fetchProfiles.rejected, (state, action) => {
        state.profilesLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedProfile, resetProfilesState, profilesLoading } = profilesDataSlice.actions;
export default profilesDataSlice.reducer;
