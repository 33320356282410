import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for fetching activities
export const fetchActivities = createAsyncThunk(
  "profile/fetchActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/activities`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      return response.data.map((activity) => ({
        id: activity.id,
        description: activity.description,
        multiplier: activity.multiplier,
      }));
    } catch (error) {
      return rejectWithValue("Error fetching activities");
    }
  }
);

// Async thunk for updating the profile
export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async ({ selectedProfileId, updatedProfile }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/profile/${selectedProfileId}`,
        updatedProfile,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      if (response.status === 200) {
        return updatedProfile;
      } else {
        return rejectWithValue("Failed to update profile");
      }
    } catch (error) {
      return rejectWithValue(error.response.data || "Error updating profile");
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    user: {},
    activities: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSelectedActivity: (state, action) => {
      state.user.activity = {
        id: action.payload.value,
        description: action.payload.label,
        multiplier: action.payload.multiplier,
      };
    },
    resetProfileState: (state) => {
      return {
        user: {},
        activities: [],
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchActivities cases
      .addCase(fetchActivities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activities = action.payload;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Handle updateProfile cases
      .addCase(updateProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        // setUser(action.payload);
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { setUser, setSelectedActivity, resetProfileState } =
  profileSlice.actions;
export default profileSlice.reducer;
