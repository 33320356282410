import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import styles from "./SearchBar.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchProducts } from "../../../../redux/slices/productSlice";

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  setSearchResults,
  products,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Fetch products only when the button is clicked
    dispatch(fetchProducts({ page: 0, size: 15, term: searchTerm }));
  };

  const handleSearchChange = (e) => {
    const inputValue = e.target.value.trim();
    setSearchTerm(inputValue);
  };

  return (
    <header className={`${styles.search__header}`}>
      <form className={styles.search} onSubmit={handleFormSubmit}>
        <div className={styles.search__icon}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
        <input
          className={styles.search__input}
          type="text"
          id="search"
          placeholder={t(`search`)}
          value={searchTerm}
          onChange={handleSearchChange} // This only updates the state
        />
        <button className={`${styles.search__button} `} disabled={false}>
          {t(`search`)}
        </button>
      </form>
    </header>
  );
};

export default SearchBar;
