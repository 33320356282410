import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../../redux/slices/productSlice";
import ProductTable from "./ProductTable/ProductTable";
import SearchBar from "./SearchBar/SearchBar";
import styles from "./ProductList.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddProductModal from "./AddProductModal/AddProductModal";

const ProductList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products, loading, error, totalPages } = useSelector(
    (state) => state.products
  );
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Lifted state for searchTerm
  const [page, setPage] = useState(0);
  const size = 14; // Define the page size
  const [isModalVisible, setModalVisible] = useState(false); // State to control modal visibility

  useEffect(() => {
    dispatch(fetchProducts({ page, size, term: searchTerm }));
  }, [page, size]);

  useEffect(() => {
    setSearchResults(products);
  }, [products]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleAddProduct = () => {
    setModalVisible(true); // Show the modal when button is clicked
  };

  const closeModal = () => {
    setModalVisible(false); // Hide the modal
  };

  // if (loading) return <div></div>;
  // if (error) return <div>{t("errorFetchingProducts")}</div>;

  return (
    <div className={styles["product-list-container"]}>
      <div className={`${styles["product-search-row"]}`}>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSearchResults={setSearchResults}
          products={products} // Pass products as a prop
        />
        <button
          className={styles["add-product-btn-wrapper"]}
          onClick={handleAddProduct}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className={styles["add-product-btn"]}
            size="3x"
          />
        </button>
      </div>

      <ProductTable products={searchResults || []} />

      <div className={styles["pagination-controls"]}>
        <button disabled={page === 0} onClick={handlePrevPage}>
          {t("pagination.previous")}
        </button>
        <span>
          {t("pagination.page")} {page + 1 > totalPages ? totalPages : page + 1}{" "}
          {t("pagination.of")} {totalPages}
        </span>
        <button disabled={page >= totalPages - 1} onClick={handleNextPage}>
          {t("pagination.next")}
        </button>
      </div>
      {/* Add the AddProductModal here */}
      <AddProductModal isVisible={isModalVisible} onClose={closeModal} />
    </div>
  );
};

export default ProductList;
