import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/ration";

export const duplicateRation = async (profileId, sourceDate, targetDates) => {
  try {
    const response = await axios.post(
      `${API_URL}/duplicate/${profileId}`, // URL with profileId in the path
      { targetDates }, // Send targetDates in the request body
      {
        params: {
          sourceDate, // Adding sourceDate as a query parameter
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`, // JWT token for authorization
          "ngrok-skip-browser-warning": "true",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error duplicating ration:", error);
    throw error;
  }
};
